<template>
  <div class="d-flex flex-column flex-root">

    <div
        class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white"
    >
      <!--begin::Aside-->
      <div
          class="login-aside d-flex flex-column flex-row-auto"
          style="background-color: #4491D1;"
      >
        <div class="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
          <a href="#" class="text-center mb-10">
            <img
                src="media/logos/footer-logo.png"
                class="max-h-70px"
                alt=""
            />
          </a>
          <h3
              class="font-weight-bolder text-center font-size-h4 font-size-h1-lg"
              style="color: #FFFFFF;"
          >
            Orko Health Ltd.
          </h3>
        </div>
        <div
            class="aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center"
            :style="{ backgroundImage: `url(${backgroundImage})` }"
        ></div>
      </div>
      <!--begin::Aside-->
      <!--begin::Content-->
      <div
          class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto"
      >
        <div class="d-flex flex-column-fluid flex-center">
          <!--begin::Signin-->

          <div class="">
            <form
                class="form"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                    class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                >
                  Welcome to Orko Health Ltd.

                </h3>

              </div>
              <div class="form-group">
                <label class="font-size-h6 font-weight-bolder text-dark"
                >Email / Phone Number</label
                >
                <div
                    id="example-input-group-1"
                    label=""
                    label-for="example-input-1"
                >
                  <input
                      class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                      type="text"
                      name="email"
                      v-model="form.userid"
                      required
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="d-flex justify-content-between mt-n5">
                  <label class="font-size-h6 font-weight-bolder text-dark pt-5"
                  >Password</label
                  >
                </div>
                <div
                    id="example-input-group-2"
                    label=""
                    label-for="example-input-2"
                >
                  <input
                      class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                      type="password"
                      name="password"
                      v-model="form.password"
                      autocomplete="off"
                      required
                  />
                </div>
              </div>
              <div class="pb-lg-0 pb-5">
                <button
                    @click.prevent="login"
                    type="submit"
                    class="btn btn-primary font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3"
                >
                  Sign In
                </button>
              </div>
            </form>
          </div>
          <!--end::Signin-->
        </div>
        <!--begin::Content footer-->

        <!--end::Content footer-->
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "login",
  data() {
    return {
      form: {
        password: "",
        userid: "",
        password_required: 1,
        user_type: 'executive',
      },
      error_msg: '',
    };
  },

  computed: {
    backgroundImage() {
      return (
          process.env.BASE_URL + "media/login/login_image.svg"
      );
    }
  },

  methods: {
    ...mapActions("auth", ["loginRequest"]),
    login() {
      this.loginRequest(this.form);
    },
  }
};
</script>
<!-- Load login custom page styles -->
<style lang="scss">
@import "../../../assets/sass/pages/login/login-1";
</style>